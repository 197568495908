// Universal

:root {
  --primary-color: rgba(255, 89, 94, 1);
  --secondary-color: rgba(22, 159, 186, 1);
  --tertiary-color: rgba(187, 173, 255, 1);
  --overlay-color: rgba(255, 89, 94, .8);
  --shadow-color: rgba(0,0,0,0.1);
  --max-width: 1240px;
  --header-height: 110px;
  --spacing: 15px;
}

html, body {
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  padding: 0;
  margin: 0;
}

// App Component
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .app-content {
    padding-top: var(--header-height);
    flex: 1 0 auto;
  }
}

// Header Component

.header-component {
  background-color: white;
  z-index: 1;
  top: 0px;
  opacity: 0.9;
  position: fixed;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);

  &::after {
    content: '';
    width: 100%;
    height: .2rem;
    position: absolute;
    background: linear-gradient(90deg, var(--primary-color) 0%, var(--tertiary-color) 50%, var(--secondary-color) 100%);
  }

  &.header--hidden {
    transform: translateY(-110%);
  }

  .header-component-inner {
    display: flex;
    padding: 1rem;
    align-items: flex-end;
    z-index: 1;
    max-width: var(--max-width);
    margin: 0 auto;
  }

  .header-component-home {
    margin: 0;
    padding: 0;
    flex-grow: 1;

    a {
      color: black;
      text-decoration: none;
    }

    h3 {
      text-transform: uppercase;
      margin: 0;
    }

    h5 {
      margin: 0;
      font-weight: normal;
    }
  }

  .header-component-nav {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-end;
    }
  
    a {
      font-size: 1.5rem;
      padding: 0 .5rem;
      transition: .3s ease;
      color: black;
  
      &:hover {
        color: var(--primary-color);
      }

      &:focus {
        color: black;
      }
    }
  }
}

// Footer component

.footer-component {
  flex-shrink: 0;
  text-align: center;
  padding: 1rem 0;
}

// Home Component 

.home-component {
  .home-component-section-title {
    display: block;
    text-align: center;
    padding: 2rem;

    >div {
      display: inline-block;
      border: .2rem solid transparent;
      border-radius: 2rem;
      background-image: linear-gradient(white, white), linear-gradient(90deg, var(--primary-color) 0%, var(--tertiary-color) 50%, var(--secondary-color) 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      text-align: center;

      >h4 {
        margin: 0;
        padding: .5rem 1rem;
      }
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    padding-bottom: var(--spacing);
  }

  .home-component-about-buttons {
    .button {
      margin: .33rem;
      display: inline-block;
      text-decoration: none;
      padding: .25rem 1rem;
      background-color: white;
      border: .1rem solid black;
      color: black;
      transition: .3s ease;

      &:hover {
        background-color: black;
        color: white;
      }

      &:focus {
        background-color: white;
        color: black;
      }
    }
  }

  .home-component-portfolio-filter {
    cursor: pointer;
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 500;

    div {
      transition: .3s ease;
    }

    div:hover {
      color: var(--primary-color);
    }

    div.active {
      color: var(--primary-color);
    }
  }

  .home-component-portfolio-content {
    display: grid;
    gap: 1rem;
    grid-template-rows: auto;
    margin: 0 auto;
    padding: 1rem;

    @media only screen and (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

// Project Card

.project-card {
  background: white;
  box-shadow: 0 2px 5px var(--shadow-color);

  .project-card-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    padding-bottom: 80%;
  }

  .project-card-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;;
    left: 0;
    right: 0;
    opacity: 0;
    color: white;
    background-color: var(--overlay-color);
    transition: .5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  
    h3, p {
      margin: .1rem;
    }

    &:hover {
      opacity: 1;
    }
  }
}

// Project Component 

.project-component {
  h3 {
    padding: 1rem 0;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: var(--secondary-color);
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: disc;
    margin-left: 2rem;
  }

  .carousel {
    padding-bottom: var(--spacing);
  }

  .carousel-control-next-icon, .carousel-control-prev-icon {
    filter: drop-shadow(2px 4px 6px black);
  }

  .project-section, p {
    margin-bottom: 1rem;
  }

  .project-navigation {
    padding: 2rem 0;

    a, button {
      transition: .3s ease;
      color: black;
      border: none;
      background-color: transparent;
      outline: none;
  
      &:hover {
        color: var(--primary-color);
      }

      &:focus {
        color: black;
      }
    }
  }
}