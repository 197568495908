body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Sansation Light';
  font-style: normal;
  font-weight: normal;
  src: local('Sansation Light'), url('./assets/fonts/Sansation_Light.woff') format('woff');
}
  
@font-face {
  font-family: 'Sansation';
  font-style: normal;
  font-weight: normal;
  src: local('Sansation'), url('./assets/fonts/Sansation_Regular.woff') format('woff');
}

@font-face {
  font-family: 'Sansation Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Sansation Bold'), url('./assets/fonts/Sansation_Bold.woff') format('woff');
}